import React from "react";
import * as classes from "./index.module.less";

export default ({ children, icon, onClick, ghost }) => (
  <button
    className={[classes.container, ghost ? classes.ghost : ""].join(" ")}
    onClick={onClick}
  >
    <span>{children}</span>
    {icon ? <img alt="icon" src={icon} /> : null}
  </button>
);
