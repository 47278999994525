export function validateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

export const TrackAnalyticsEvent = (category, event) => {
  if (typeof window !== "undefined" && !!window.ga) {
    window.ga("send", "event", category, event);
  }
};

export const handleFormSubmission = (form) => {
  switch (form) {
    case "conquerMarketForm":
      createIframe("https://appinchina.co/?conf=2");
      break;
    case "getStartedForm":
      createIframe("https://appinchina.co/?conf=7");
      break;
    case "marketForm":
      createIframe("https://appinchina.co/?conf=4");
      break;
    case "servicesForm":
      createIframe("https://appinchina.co/?conf=6");
      break;
    case "aiconForm":
      createIframe("https://appinchina.co/?conf=5");
      break;
    case "contactForm":
      createIframe("https://appinchina.co/?conf=1");
      break;
    default:
      break;
  }
};

export const formatBlogDate = (string) => {
  let date = new Date(string);
  return `${getMonthString(
    date.getMonth()
  )} ${date.getDate()}, ${date.getFullYear()}`;
};

let createIframe = (src) => {
  var i = document.createElement("iframe");
  i.style.display = "none";
  i.onload = () => {
    i.parentNode.removeChild(i);
  };
  i.src = src;
  document.body.appendChild(i);
};

export const decodeEntities = (function () {
  if (typeof document === "undefined") return () => {};
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  function decodeHTMLEntities(str) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }
  return decodeHTMLEntities;
})();

export const getMonthString = (number) => {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[number];
};

export const parseWpUrl = (node) => {
  let { slug, categories, link } = node;
  if (
    !!categories &&
    !!categories.nodes &&
    !!categories.nodes.length &&
    categories.nodes[0].slug
  ) {
    return `/${
      !!categories.nodes &&
      !!categories.nodes.length &&
      categories.nodes[0].slug
    }/${slug}/`;
  } else {
    return link && !link.includes("//wp.appinchina.co/") ? link : "/" + slug;
  }
};

export const getRelatedContent = (related_content, allWpPage, allWpPost) => {
  let relatedContent = [];
  [
    { slug: related_content.wordpressSlug1, title: related_content.title1 },
    { slug: related_content.wordpressSlug2, title: related_content.title2 },
    { slug: related_content.wordpressSlug3, title: related_content.title3 },
  ].forEach((it) => {
    let page = allWpPage.edges
      .concat(allWpPost.edges)
      .find(
        ({ node }) =>
          it.slug &&
          (it.slug.includes(node.slug) ||
            it.slug.replace(/\//g, "").includes(node.slug.replace(/\//g, "")))
      );
    if (page) {
      relatedContent.push({
        ...page,
        url: parseWpUrl(page.node),
      });
    } else if (it.slug && it.title) {
      relatedContent.push({
        node: {},
        url: it.slug.replace("https://appinchina.co", ""),
        title: it.title.replace("https://appinchina.co", ""),
      });
    }
  });
  return relatedContent;
};

function getURLParameter(name) {
  if (typeof window === "undefined") return;
  return decodeURIComponent(
    (RegExp(`${name}=(.+?)(&|$)`).exec(window.location.search) || [
      undefined,
      "error",
    ])[1]
  );
}

export const setReferrerAndSource = () => {
  if (typeof window === "undefined") return;

  // Get lastUpdated from local storage
  var lastUpdated = window.localStorage.lastUpdated;
  
  // If lastUpdated is not set, set it to now
  if (!lastUpdated) {
    window.localStorage.lastUpdated = Date.now();
  }

  // If lastUpdated is more than 10 minutes ago, set source to undefined
  if (Date.now() - lastUpdated > 600000) {
    window.localStorage.source = undefined;
  }

  // Set lastUpdated to now
  window.localStorage.lastUpdated = Date.now();

  var source = "";
  if (getURLParameter("gclid") !== "error") source = "Google Ads";
  else if (getURLParameter("s") === "f") source = "Facebook";
  else if (getURLParameter("s") === "fa") source = "Facebook Ads";
  else if (getURLParameter("s") === "la") source = "Linkedin Ads";
  else if (getURLParameter("s") === "t") source = "Twitter";
  else if (getURLParameter("s") === "l") source = "Linkedin";
  else if (getURLParameter("s") === "q") source = "Quora";
  else if (getURLParameter("s") === "o") source = "Stackoverflow";
  else if (getURLParameter("s") === "r") source = "Reddit";
  else if (getURLParameter("s") === "m") source = "Medium";

  if (getURLParameter("gclid") !== "error"){
    console.log("gclid", getURLParameter("gclid"));
    // save this gclid to local storage
    window.localStorage.gclid = getURLParameter("gclid");
  }else{
    // clear the gclid from local storage
    window.localStorage.gclid = undefined;
  }
  
  if (source) {
    window.localStorage.source = source;
  }

  if (document.referrer) {
    if (!!document.referrer && !document.referrer.includes("appinchina.co")) {
      window.localStorage.referrer = document.referrer;
    }
  } else {
    if (!window.localStorage.referrer) {
      window.localStorage.referrer = "No referrer";
    }
  }

  if (!window.localStorage.first_page) {
    window.localStorage.first_page = window.location?.href?.slice(0, 400);
  }
};

export const removeUnderLineFromImgs = () => {
  if (typeof window === "undefined" || typeof document === "undefined") return;
  Array.from(document.querySelectorAll("a")).forEach((a) => {
    if (a.querySelector("img")) {
      a.style.borderBottom = "0";
    }
  });
};
