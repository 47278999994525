import React from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";

export default ({ children, title, to, external }) =>
  external ? (
    <a
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      className={classes.linkContainer}
    >
      <h4>{title}</h4>
      <div>{children}</div>
    </a>
  ) : (
    <Link to={to} className={classes.linkContainer}>
      <h4>{title}</h4>
      <div>{children}</div>
    </Link>
  );
