import React from "react";
import { Helmet } from "react-helmet";
import logo from "images/favicon.ico";
import METADATA from "src/utils/metadata.js";
import og from "images/other/og.jpeg";

const isNotBrowser = () => typeof window === "undefined" || typeof document === "undefined";

let injectGoogleTagManagerScript = () => {
  if (isNotBrowser()) {
    return;
  }
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],

      j = d.createElement(s),
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-5DGJRJL");
};

let injectGA4Script = () => {
    if(isNotBrowser()) {
      return;
    }
    (function (window, document) {
      window.dataLayer = window.dataLayer || [];
    
      function gtag(){window.dataLayer.push(arguments);}
    
      gtag('js', new Date())
      gtag('config', 'G-GEP12RJ5BB')
    })(window, document);
};

export default ({
  url,
  defaultTitle,
  defaultDescription,
  defaultDeaturedImage,
}) => {
  let canonical =
    "https://appinchina.co" +
    url
      ?.replace("https://www.appinchina.co", "")
      .replace("http://www.appinchina.co", "")
      .replace("https://appinchina.co", "")
      .replace("http://appinchina.co", "");
  let data = METADATA[url] || {};
  let { title, description, featuredImage } = data;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{defaultTitle || title}</title>
        <link
          rel="canonical"
          href={canonical.replace(/\/\/+/g, "/").replace(/:\//g, "://")}
        />
        <meta name="description" content={defaultDescription || description} />
        <link rel="shortcut icon" href={logo} />
        <meta name="theme-color" content="#8cbe3f" />
        <html lang="en" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@appinchina" />
        <meta name="twitter:creator" content="@appinchina" />

        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={
            defaultDeaturedImage ||
            `http://appinchina.co${featuredImage || og}`
          }
        />
        <meta
          property="og:image:secure_url"
          content={
            defaultDeaturedImage ||
            `https://appinchina.co${featuredImage || og}`
          }
        />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta property="og:type" content="website" />

        {/* Google Tag Manager  Script */}
        <script>{injectGoogleTagManagerScript()}</script>

        {/* Google GA4 (Analitycs) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GEP12RJ5BB"></script>
        <script>{injectGA4Script()}</script>

      </Helmet>
    </>
  );
};
