import React, { useEffect } from "react";
import { setReferrerAndSource } from "src/utils/functions";

export default () => {
  useEffect(() => {
    setReferrerAndSource();
    injectScripts();
  }, []);

  let injectScripts = () => {
    if (typeof window === "undefined" || typeof document === "undefined") {
      return;
    }
    (function (w, d) {
      var token = "3fe84754-89da-48b9-9998-861423ed9a1f";
      var script = d.createElement("script");
      script.async = true;
      script.src =
        "https://track.exactvisitor.com/assets/js/for-cache.min.js?s=ev";
      script.onload = function () {
        w.salesToolsObserverCached(token);
      };
      d.body.appendChild(script);
    })(window, document);
  };

  return (
    <div>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/6335881.js"
      ></script>
    </div>
  );
};
