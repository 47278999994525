// extracted by mini-css-extract-plugin
export var addresAndCopy = "index-module--addresAndCopy--xK0Dc";
export var awsSelectPartner = "index-module--awsSelectPartner--bwsCN";
export var britishChamber = "index-module--britishChamber--dOl5g";
export var buttons = "index-module--buttons--u5L7I";
export var chinaBritainBusinessCouncil = "index-module--chinaBritainBusinessCouncil--8Vot2";
export var container = "index-module--container--kQUZh";
export var dark = "index-module--dark--MpK29";
export var emailInput = "index-module--emailInput--GfFa5";
export var formButton = "index-module--formButton--WcUuq";
export var formContainer = "index-module--formContainer--9ds9o";
export var icon = "index-module--icon--8D5ij";
export var insideContainer = "index-module--insideContainer--yVXr9";
export var largeButtons = "index-module--largeButtons--1M1Jb";
export var left = "index-module--left--Impyo";
export var linksGroup = "index-module--linksGroup--5eegE";
export var memberships = "index-module--memberships--RIItw";
export var partners = "index-module--partners--UqeeK";
export var partnersGroup = "index-module--partnersGroup--uccbc";
export var partners_with_wrap = "index-module--partners_with_wrap--xgHm5";
export var privacyPolicy = "index-module--privacyPolicy--sJ-u4";
export var right = "index-module--right--MOaDb";
export var siteLinksContainer = "index-module--siteLinksContainer--JFQwJ";
export var smallButtons = "index-module--smallButtons--i+6-I";
export var whithoutBullets = "index-module--whithoutBullets--gr8YG";